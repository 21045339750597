<template>
  <section class="invention-navigation">
    <header>
      <hub-button variant="icon" color="primary" @click="createVisible = true">
        <hub-icon name="text-box-plus"></hub-icon>
      </hub-button>
      <hub-create-invention v-if="createVisible" @created="created" @close="createVisible = false"></hub-create-invention>
    </header>
    <div class="navigation-wrp" style="overflow-y:scroll">
      <div class="accordion-item">
        <div class="header" @click="myExpanded = !myExpanded">
          <hub-button variant="icon">
            <hub-icon v-if="!myExpanded" name="chevron-up" />
            <hub-icon v-else name="chevron-down" />
          </hub-button>
          <div class="title">
            <div>Recently visited</div>
            <div v-if="my?.data?.length" class="count">({{ my?.data?.length || 0 }})</div>
          </div>
        </div>
        <div v-if="!myExpanded" class="invention-navigation-list">
          <ul v-if="!isGetMyCollectionRequestPending" style="marging-top: 0.25rem">
            <li v-for="item of my.data" :key="item.id" class="invention-navigation-list-item">
              <router-link :to="`/inventions/${item.id}`">
                <h4 class="invention-navigation-list-item-title">
                  <label>#</label>
                  <ul>
                    <li v-for="reference of item.references" :key="reference">{{ reference }}</li>
                  </ul>
                </h4>
                <div>{{ item.title }}</div>
              </router-link>
              <hub-button variant="icon" class="remove" @click="removeFromList(item)"><hub-icon name="close" size="sm"></hub-icon></hub-button>
            </li>
          </ul>
          <div v-else class="loading-wrapper">
            <hub-icon name="loading" spin size="lg" style="justify-self: center; align-self: center;"></hub-icon>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div>
          <div class="header" @click="myExpanded = !myExpanded">
            <hub-button variant="icon">
              <hub-icon v-if="myExpanded" name="chevron-up" />
              <hub-icon v-else name="chevron-down" />
            </hub-button>
            <div class="title">
              <div>My active inventions</div>
              <div v-if="collection?.length" class="count">({{ collection?.length || 0 }})</div>
            </div>
          </div>
          <div v-if="myExpanded" class="invention-navigation-list">
            <ul v-if="!isGetInventionsRequestPending">
              <li v-for="item of collection" :key="item.id" class="invention-navigation-list-item">
                <router-link :to="`/inventions/${item.id}`">
                  <h4 class="invention-navigation-list-item-title">
                    <label>#</label>
                    <ul>
                      <li v-for="reference of item.references" :key="reference">{{ reference }}</li>
                    </ul>
                  </h4>

                  <div>{{ item.nextTask?.invention?.title }}</div>
                </router-link>
              </li>
            </ul>
            <div v-else class="loading-wrapper">
              <hub-icon name="loading" spin size="lg" style="justify-self: center; align-self: center;"></hub-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import Button from '../../common/Button';
import Icon from '../../common/Icon';

import Create from '../Create';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,

    'hub-create-invention': Create
  },
  data() {
    return {
      createVisible: false,
      myExpanded: true
    };
  },
  computed: {
    ...mapGetters({
      collection: 'reports/myInventions/collection'
    }),
    ...mapState({
      my: s => s.inventions.my,
      isGetMyCollectionRequestPending: s => s.inventions.isGetMyCollectionRequestPending,
      isGetInventionsRequestPending: s => s.reports.myInventions.isRequestPending
    })
  },
  watch: {
    async '$route.params.id'(prev, next) {
      if (!this.$route.params.id) {
        return;
      }

      this.ensureAddedToList();
    },
    async isGetMyCollectionRequestPending(nw, ow) {
      if (nw === false && ow === true) {
        await this.ensureAddedToList();
      }
    }
  },
  async created() {
    await this.ensureAddedToList();
  },

  methods: {
    async removeFromList(item) {
      await this.$store.dispatch('inventions/remove', item);
      if (item.id === this.$route.params.id) {
        this.$router.push({ path: `/inventions` });
      }
    },
    async created(item) {
      await this.$store.dispatch('inventions/add', item);
      this.$router.push({ path: `/inventions/${item.id}` });
      this.createVisible = false;
    },
    async ensureAddedToList() {
      if (this.$route.params.id && this.my && !this.my.data.find(({ id }) => id === this.$route.params.id)) {
        const item = await this.$store.dispatch('inventions/getById', this.$route.params.id);
        await this.$store.dispatch('inventions/add', item);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.invention-navigation {
  background-color: var(--theme-surface);
  height: 100%;

  > header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    button {
      margin: 0 0.25rem;
    }
  }
  .navigation-wrp {
    height: calc(100% - 50px);
    overflow-y: scroll;
    margin-left: 5px;
  }

  .accordion-item {
    background: var(--theme-background);
    margin-bottom: 0.25rem;
    border-radius: 2px;
    cursor: pointer;
    .header {
      width: 100%;
      display: grid;
      padding: 10px;
      grid-template-columns: 50px 1fr;
      align-items: center;
      font-weight: 450;
      color: var(--theme-on-default);

      .title {
        font-size: 1rem;
        user-select: none;
        display: flex;
        align-content: center;

        .count {
          margin-left: 0.25rem;
          font-size: 0.9rem;
        }
      }
    }
    .loading-wrapper {
      text-align: center;
      margin: 1rem;
    }
  }
}

.invention-navigation-list {
  margin: 0.25rem;
  margin-bottom: 0;
  padding-bottom: 1px;
  user-select: none;
}

.invention-navigation-list-item {
  background: var(--theme-surface);
  margin-bottom: 0.25rem;
  transition: 0.5s all;
  position: relative;
  border-radius: 2px;
  a {
    display: block;
    padding: 0.75rem 0.75rem;
    &.router-link-active {
      background-color: var(--theme-highlight);
    }
  }
  h4 {
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
    font-weight: 700;
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr);
    grid-gap: 0.25rem;
    color: var(--theme-on-surface-accent);
    > ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        margin-right: 0.25rem;
        &:not(:last-child) {
          &:after {
            content: ', ';
          }
        }
      }
    }
  }

  div {
    font-size: 0.8rem;
    padding-left: 1rem;
    color: var(--theme-on-surface);
  }

  &:hover {
    background-color: var(--theme-highlight);
    cursor: pointer;
    .remove {
      display: block;
    }
  }

  .remove {
    position: absolute;
    right: 0;
    top: 0;
    // display: none;
  }
}
</style>
