<template>
  <section class="create-invention-wrapper">
    <section class="create-invention">
      <header>
        <h2>Create New Invention</h2>
        <hub-button variant="icon" class="close-button" @click="$emit('close')"><hub-icon name="close"></hub-icon></hub-button>
      </header>
      <form :class="{ dirty: v$.$anyDirty }" @submit.prevent="create">
        <div>
          <hub-text-field v-model="title" label="Title" :test-id="'create-invention-title'"></hub-text-field>
          <ul v-if="v$.title.$invalid">
            <li v-if="v$.title.required.$invalid" class="error">Title is required</li>
            <li v-if="v$.title.minLength.$invalid" class="error">Title must be longer than or equal to 5 characters</li>
          </ul>
        </div>
        <div>
          <hub-multiselect :key="timestamp" v-model:value="references" label="References" placeholder="" :taggable="true"></hub-multiselect>

          <ul v-if="v$.references.$invalid">
            <li v-if="v$.references.required.$invalid" class="error">References are required</li>
            <li v-if="v$.references.minLength.$invalid" class="error">You should provide at least one reference</li>
            <li v-if="v$.references.unique.$invalid" class="error">One or more references already exist</li>
            <li v-if="v$.references.format.$invalid" class="error">One or more references have incorrect format</li>
          </ul>
        </div>
        <div v-if="isCreateRequestFailed">
          <ul>
            <li class="error center">Unable to create this invention. Please, try again later or contact our development team.</li>
          </ul>
        </div>
        <div style="justify-self: end">
          <hub-button color="primary" variant="contained" :disabled="isCreateRequestPending || (v$.$anyDirty && v$.$invalid)">Create</hub-button>
        </div>
      </form>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

import Button from './../common/Button.vue';
import TextField from './../common/TextField.vue';
import Icon from './../common/Icon.vue';
import Multiselect from './../common/Multiselect';

import logger from '@/utils/logger';

export default {
  components: {
    'hub-button': Button,
    'hub-text-field': TextField,
    'hub-multiselect': Multiselect,
    'hub-icon': Icon
  },
  emits: ['created', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: '',
      references: [],

      timestamp: +new Date(),
      duplicates: []
    };
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.inventions.isCreateRequestPending,
      isCreateRequestFailed: s => s.inventions.isCreateRequestFailed
    })
  },
  validations() {
    return {
      title: { required, minLength: minLength(5) },
      references: {
        required,
        minLength: minLength(1),
        unique: value => value.every(text => this.validateReferenceUniqueness({ text })),
        format: value => value.every(text => this.validateReferenceFormat({ text }))
      }
    };
  },
  methods: {
    validateRecipient({ text }) {
      if (!text || !text.length) {
        return true;
      }

      const regexp = /[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/;

      if (regexp.exec(text)) {
        return false;
      }

      return this.vars.indexOf(text) === -1;
    },
    async create() {
      this.v$.$touch();

      if (this.v$.$error) {
        return;
      }

      try {
        const item = await this.$store.dispatch('inventions/create', {
          title: this.title,
          references: this.references
        });

        this.$emit('created', item);
        this.v$.$reset();
      } catch (e) {
        try {
          const { references } = await e.response.json();
          this.duplicates = Array.isArray(references) ? [...references] : [];
          this.timestamp = +new Date();
        } catch (e) {
          logger.error(e);
        }
        logger.error(e);
      }
    },
    validateReferenceUniqueness({ text }) {
      if (!text || !text.length) {
        return false;
      }
      if (this.duplicates.includes(text)) {
        return false;
      }

      return true;
    },
    validateReferenceFormat({ text }) {
      const re = /^\S+:\S+$/;

      return re.test(text);
    }
  }
};
</script>

<style lang="scss" scoped>
.create-invention-wrapper {
  background: var(--theme-surface);
  color: var(--theme-on-surface);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.create-invention {
  display: grid;
  grid-gap: 1rem;
  padding: 2rem 15% 1rem 15%;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content max-content minmax(0, 1fr);
  max-width: 1248px;
  margin: auto;

  header {
    border-bottom: 1px solid var(--theme-highlight);
    padding-bottom: 0.5rem;

    h2 {
      text-transform: uppercase;
    }
  }

  .close-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  form {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 1rem;
    width: 100%;
    align-items: baseline;
    max-width: 800px;
  }
}
</style>
